<template>
    <div class="top ">
        <div class="mbc_wrap main_bg" :class="{'all' : this.UserInfo.mb_type != 1}"> <!--멘토가 아니라면 class="all" 추가-->
            <div class="home_subtitle">우리 아이들의 꿈을 응원하는<br> <span>아빠미소멘토단</span></div>
            <div class="home_category">
                <div class="hc_button" :class="{'active' : filterCategory === ''}" @click="setFilterFunc('ca', '')">
                    <div class="hc_img"></div>
                    <div class="hc_text">직업전체</div>
                </div>
                <div class="hc_button"
                     v-for="job in categoryList"
                     :key="`jobCate${job.mjc_idx}`"
                     v-if="hasSelCategory(job.mjc_idx)"
                     :class="{'active' : Number(filterCategory) === Number(job.mjc_idx)}"
                     @click="setFilterFunc('ca', job.mjc_idx)"
                >
                    <div class="hc_img job-category" :class="[`job_${job.mjc_idx}`]"></div> <!--카테고리 클래스 job-category랑 job_{{카테고리번호}}-->
                    <div class="hc_text">{{job.mjc_job}}</div>
                </div>
            </div>
        </div>
        <div class="searchFilter clear">
            <div class="status">
                <span :class="{'active' : filterStatus === ''}" @click="setFilterFunc('status', '')">전체</span>
                <span :class="{'active' : filterStatus === 1}" @click="setFilterFunc('status', 1)">진행중</span>
                <span :class="{'active' : filterStatus === 2}" @click="setFilterFunc('status', 2)">마감</span>
            </div>
            <div class="search_box">
                <el-select class="select center" v-model="filterPublic" @change="setFilterFunc('pr', filterPublic)" v-if="UserInfo.mb_type != 3">
                    <el-option value="" label="센터 전체">센터 전체</el-option>
                    <el-option v-for="provider in providerList" :key="`provider${provider.p_idx}`" :value="provider.p_idx" :label="provider.p_name"></el-option>
                </el-select>
                <el-select class="select type" v-model="filterInputOption">
                    <el-option :value="'su'" :label="'제목'"></el-option>
                    <el-option :value="'na'" :label="'이름'"></el-option>
                </el-select>
                <div class="input">
                    <input type="text" v-model="filterInput" @keypress.enter="setFilterFunc(filterInputOption, filterInput)"></input>
                    <button class="search_btn" @click="setFilterFunc(filterInputOption, filterInput)">검색</button>
                </div>
            </div>
            <div class="search_wrap">
                <button class="search_btn"></button>
                <div class="search_box" >

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import EventBus from "@/utils/event-bus";
    import {mapState, mapGetters} from "vuex";
    import boardFilterUtil from "@/components/board/boardFilterUtil";
    const {filterConfig} = boardFilterUtil;

    export default {
        name: "BoardFilterLetterLayout",
        inject: ['setPage', 'setData', 'setFilter'],
        props: ['filterData'],
        data() {
            return {
                filterCategory: '',
                filterStatus: '',
                filterPublic: '',
                filterInput: '',
                filterInputOption: 'su',

                categoryList: [],
                providerList: [],
            }
        },
        created() {
            this.getCategory();
            this.getProvider();
            this.setterFilterDataInData();
        },
        mounted() {

        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
        },
        methods: {
            setterFilterDataInData() {
                let classnames = Object.keys(this.filterData);
                classnames.forEach(classname => {
                    let obj = this.filterData[classname];
                    let type = filterConfig[obj.key].type;
                    if(this.hasOwnProperty(classname)) {
                        // this[classname] =
                        if(type == 'query') {
                            this[classname] = obj.query;
                        }else if(type === 'between') {
                            this[classname] = [obj.start, obj.end];
                        }else if(type === 'match') {
                            if(isNaN(obj.value)) {
                                this[classname] = obj.value;
                            }else{
                                this[classname] = Number(obj.value);
                            }

                        }

                    }
                })
            },
            getCategory() {
                this.$api.$board.getJobCategory().then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1) {
                        this.categoryList = res.List;
                    }
                })
            },
            getProvider() {
                this.$api.$board.getProvider({ip:0}).then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1) {
                        this.providerList = res.List;
                        this.$forceUpdate();
                    }
                })
            },
            hasSelCategory(idx) {
                if(this.UserInfo.mb_type != 1) {
                    return true;
                }
                let alarmList = this.UserInfo.memberJob;
                return alarmList.findIndex(job => job.mjc_idx === idx) > -1;
            },
            returnCategoryClass(idx) {
                return jobCategoryClass[idx];
            },
            setFilterFunc(key, value) {
                console.log(key);
                console.log(filterConfig)
                let classname = filterConfig[key].classname;
                if(!classname) {
                    return;
                }
                this[classname] = value;
                this.setData(classname, value);
                this.setFilter(key, value);
            },
            hasActive(idx) {
                if (this.selectCate === idx) {
                    return 'active';
                }
                return '';
            },
            moveSearchPage() {
                this.$router.replace(this.$route.path);
                this.$nextTick(() => {
                    this.$router.push('/letter')
                })
            },

        },
        watch: {},

    }
</script>

<style scoped>

</style>